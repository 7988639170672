import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Flex, Box } from 'rebass'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import Layout from '../components/Layout'

const Terms = props => {
  const {
    data: { contentfulDynamicContent },
  } = props

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
    renderText: text => text.replace('!', '?'),
  }

  return (
    <Layout {...props}>
      <Flex px={3} justifyContent="center" style={{ marginTop: 250 }}>
        <Box p={2} width={[1, 1 / 2]} style={{ maxWidth: '720px' }}>
          {documentToReactComponents(
            contentfulDynamicContent.description.json,
            options
          )}
        </Box>
      </Flex>
    </Layout>
  )
}

const Text = styled.p`
  font-size: ${props => props.theme.fontSizes[1]}px;
`

export default Terms

export const TermsContent = graphql`
  query GetTerms {
    contentfulDynamicContent(slug: { eq: "terms" }) {
      id
      title
      slug
      description {
        json
      }
    }
  }
`
